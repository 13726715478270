import React from "react"
import filesize from "filesize"
import play from "../../images/documents/play.svg"
import fileIcon from "../../images/documents/file.svg"
import arrow from "../../images/documents/download-arrow.svg"

export default ({ name, file, language }) => {
  const videoFormats = [
    "webm",
    "mkv",
    "flv",
    "avi",
    "wmv",
    "mp4",
    "m4p",
    "m4v",
    "mpg",
    "mpeg",
    "m2v",
  ]

  return (
    <div className="mt-4">
      <a
        href={getProperUrl(file.url)}
        location={getProperUrl(file.url)}
        download={name.text}
        className="resource flex items-center justify-between my-6"
      >
        <div className="flex items-center space-x-4 xs:space-x-6 sm:space-x-8">
          <div className="resource-icons">
            {videoFormats.includes(getExtensionFromUrl(file.url).toLowerCase()) ? (
              <span>
                <img src={play} alt="Play icon" />
              </span>
            ) : (
              <span>
                <img src={fileIcon} alt="File Icon" />
              </span>
            )}
          </div>

          <div className="space-y-1 self-center">
            <h4 className="text-almost-black text-opacity-80 font-medium text-base">
              {name.text}
            </h4>

            <div className="text-almost-black text-opacity-80 text-sm">
              <span>{language}</span>,{" "}
              <span className="uppercase">{getExtensionFromUrl(file.url)}</span>
              , <span>{filesize(file.size, { round: 1 })}</span>
            </div>
          </div>
        </div>

        <div className="hidden xs:block mr-5 download-arrow invisible">
          <img src={arrow} alt="Download arrow icon" />
        </div>
      </a>
    </div>
  )
}

function getExtensionFromUrl(url) {
  return url.split(/[#?]/)[0].split(".").pop().trim()
}

function getProperUrl(url) {
  return url.split('?')[0]
}
