import React from "react"
import { Helmet } from "react-helmet"
import CookiesNotice from "../components/cookies-notice"
import Footer from "../components/footer/footer"
import Header from "../components/header/header"
import Hero from "../components/hero/section"
import Main from "../components/documents"

export default function Documents() {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Join the #WeRemember Campaign!</title>
        <meta
          content="The campaign for International Holocaust Remembrance Day."
          name="description"
        />
        <meta content="width=device-width, initial-scale=1" name="viewport" />
      </Helmet>

      <Header />
      <Hero />
      <Main />
      <Footer />
      <CookiesNotice />
    </div>
  )
}
