import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Document from "./document"

export default () => {
  let [currentLanguages, setCurrentLanguages] = useState([])
  let [currentDocumentTypes, setCurrentDocumentTypes] = useState([])

  let {
    allPrismicDocumentLanguages: { nodes: languages },
    allPrismicDocuments: { nodes: documents },
  } = useStaticQuery(graphql`
    query data {
      allPrismicDocumentLanguages(sort: { fields: data___name___text }) {
        nodes {
          id
          uid
          data {
            name {
              text
            }
          }
        }
      }
      allPrismicDocuments(sort: {fields: last_publication_date, order: DESC}) {
        nodes {
          id
          data {
            document_type
            name {
              text
            }
            file {
              url
              size
              type
            }
            language {
              uid
            }
          }
          last_publication_date
        }
      }
    }
  `)

  let allDocumentTypes = []
  documents.map(doc => allDocumentTypes.push(doc.data.document_type))
  allDocumentTypes = [...new Set(allDocumentTypes)].filter(type => type != null)

  //Filter by document type and by languages
  if (currentDocumentTypes.length > 0) {
    documents = documents.filter(({ data }) =>
      currentDocumentTypes.includes(data.document_type)
    )
  }

  if (currentLanguages.length > 0) {
    documents = documents.filter(({ data }) =>
      currentLanguages.includes(data.language.uid)
    )
  }

  return (
    <div id="files" className="container">
      <div className="grid md:grid-cols-12 gap-8">
        <div className="md:col-span-10 md:col-start-2 mt-10 mb-24">
          <h2 className="text-almost-black font-semibold capitalize text-2xl md:text-3xl lg:text-3xxl">
            Files
          </h2>
          <p className="mt-6 text-black capitalize font-medium text-lg">
            Filter by Document Type
          </p>
          <div className="mt-6 flex flex-wrap items-center pb-6 border-b border-almost-black border-opacity-20">
            {allDocumentTypes.map((type, index) => (
              <button
                key={index}
                onClick={() => {
                  if (currentDocumentTypes.includes(type)) {
                    setCurrentDocumentTypes(
                      currentDocumentTypes.filter(t => t !== type)
                    )
                  } else {
                    setCurrentDocumentTypes([...currentDocumentTypes, type])
                  }
                }}
                className={`
                  mr-4 mb-4 px-4 py-3 font-medium text-base border border-almost-black rounded-sm
                  hover:bg-almost-black hover:text-yellow
                  ${
                    currentDocumentTypes.includes(type)
                      ? "bg-almost-black text-yellow"
                      : "text-almost-black"
                  }
                `}
              >
                {/* Rename some of the categories without need to change the backend categories */}
                  { type === 'Fact' ? 'Social Media Graphics' : '' }
                  { type === 'Projection' ? 'Projection Files' : '' }
                  { type != 'Fact' && type != 'Projection' ? type : '' }
              </button>
            ))}
          </div>

          <p className="mt-6 text-black capitalize font-medium text-lg">
            Filter by Language
          </p>
          <div className="mt-6 flex flex-wrap items-center">
            {languages.map(({ id, uid, data: { name } }) => (
              <button
                key={id}
                onClick={() => {
                  if (currentLanguages.includes(uid)) {
                    setCurrentLanguages(currentLanguages.filter(l => l !== uid))
                  } else {
                    setCurrentLanguages([...currentLanguages, uid])
                  }
                }}
                to={
                  currentLanguages.includes(uid)
                    ? `/documents#files`
                    : `/documents/${uid}#files`
                }
                className={`
                  mr-4 mb-4 px-4 py-3 font-medium text-base border border-almost-black rounded-sm
                  hover:bg-almost-black hover:text-yellow
                  ${
                    currentLanguages.includes(uid)
                      ? "bg-almost-black text-yellow"
                      : "text-almost-black"
                  }
                `}
              >
                {name.text}
              </button>
            ))}
          </div>

          <div className="mt-6">
            {documents.map(({ id, data }) => (
              <Document
                key={id}
                name={data.name}
                file={data.file}
                language={
                  languages.find(({ uid }) => uid === data.language.uid)?.data
                    ?.name?.text
                }
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
